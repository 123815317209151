// ================================================================================
// Admin interfaces
// ================================================================================

.purchaseorgs-filter {
	margin-bottom: 15px;

	.nav > li > a {
		transition: all 250ms;

		&:hover, 
		&:focus {
			background: #c0c0c0;	
		} 
	}
	
	.nav-pills > li.active > a {
		font-family: $font-family-bold;
	}
}