// ================================================================================
// Card Panels
// ================================================================================

$panel-default-heading-bg: $brand-grey;
$panel-default-text: $brand-white;
$panel-border-radius: 0;
$alert-border-radius: 0;

.card-panel {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 60px;
	}

	.table > thead > tr > th {
		border-bottom: 3px solid $table-border;
	}

	.panel-heading {
		text-transform: uppercase;
	}

  .review-message {
    background: $brand-yellow;
    padding: 8px 15px;
  }

  .panel-body {

    .exceptions {

      &:before {
        content: '';
        border-top: 1px solid $table-border;
        display: block;
        padding-top: 15px;
        margin-left: -15px;
        margin-right: -15px;
      }

      h1 {
        font-size: 16px;
        margin-top: 0;
      }
    }

  }

	.panel-footer {

		h2 {
			font-size: 14px;
			margin-top: 10px;
		}

		.pull-left {
			margin-right: 15px;

			p {
				margin-bottom: 5px;
			}

			&.is-note {
				width: 300px;
			}
		}

		.pull-right {
			margin-top: 0;
		}

	}

	.form-group {
		margin-bottom: 0;
	}

	table {
		tr {
			&.is-substituted {
				background-color: $brand-light-grey-alt;
			}
			&.is-alternate {
				background-color: $table-alternate;
			}
		}
	}

	.table > tbody > tr > td {
  	vertical-align: middle;
	}

	.rfq-table-heading {
		background: $brand-light-grey;

		h2 {
			font-size: 15px;
			padding: 10px 15px;
			margin: 0;
		}
	}

	&.is-mvinbound {
		.panel-footer .pull-right {
			text-align: right;

			.text-danger {
				display: block;
				margin-bottom: 8px;
			}
		}
	}

}

.panel-title {
	font-family: $font-family-bold;

	&.pull-left {
		padding-top: 3px;
	}
}

// Expand Collapse for Long Text

.long_text-content {
	max-width: 300px;
}

[data-toggle="collapse"] {
    &.collapsed .if-not-collapsed {
         display: none;
    }
    &:not(.collapsed) .if-collapsed {
         display: none;
    }
}

// Oustanding RFQs Boxes

.outstanding-rfqs {

	.panel {
		border: 0;
	}

	.panel-body {
		font-size: 60px;
		font-family: $font-family-bold;
		padding: 5px;
	}

	.panel-footer {
		font-family: $font-family-bold;
		text-transform: uppercase;
	}

}

.table > tbody > tr > td.dispatch-form{
	vertical-align: bottom;
}

.glyphicon-exclamation-sign { 
	color: #C0392B; 
}
.glyphicon-wrench { 
	color: orange; 
}