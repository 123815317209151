// ================================================================================
// Animations
// ================================================================================

// -----------------------------------------------------------------------------
// CSS Ajax Spinners
// -----------------------------------------------------------------------------

$spinner-lg: 60px;
$spinner-sm: 24px;
$spinner-color: rgb(84,88,90); // #54585a;
$spinner-color-tint: rgba($spinner-color, 0.2);
$spinner-spin-duration: 1s;

.loading {
  animation: spin $spinner-spin-duration infinite linear;
  border-radius: 50%;
  border: .25rem solid $spinner-color-tint;
  border-top-color: $spinner-color;
  margin: 30px auto 0 auto;
  height: $spinner-lg;
  width: $spinner-lg;
}

.loading-modal {
  animation: spin $spinner-spin-duration infinite linear;
  border-radius: 50%;
  border: .25rem solid $spinner-color-tint;
  border-top-color: $spinner-color;
  margin: 0 auto 0 auto;
  height: $spinner-lg;
  width: $spinner-lg;
}

.loading-btn {
  animation: spin $spinner-spin-duration infinite linear;
  border-radius: 50%;
  border: .15rem solid $spinner-color-tint;
  border-top-color: $spinner-color;
  margin: 0 auto;
  height: $spinner-sm;
  width: $spinner-sm;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
