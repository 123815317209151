// ================================================================================
// Mixins
// ================================================================================

// Bootstrap Breakpoint mixin
// Usage: @include bp(xs) { // your styles  }
@mixin bp($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }
 
  @else if $class == s {
    @media (min-width: 768px) { @content; }
  }
 
  @else if $class == m {
    @media (min-width: 992px) { @content; }
  }
 
  @else if $class == l {
    @media (min-width: 1200px) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, m, l";
  }
}

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.cf {
  @include clearfix();
}