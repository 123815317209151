// ================================================================================
// Header
// ================================================================================

header {

	.navbar-inverse {
		background-color: $brand-grey;
		border-radius: 0;

		.navbar-nav > li > a {
			color: $header-text;
			font-family: 'ars_maquette_web_onebold', Arial;
			text-transform: uppercase;


			@include bp(l) {
				&#dropAdmin:hover:before {
					background: $brand-light-grey;
				}
				&#dropCannington:hover:before {
					background: #91aba1;
				}
				&#dropWorsley:hover:before {
					background: #A4C8E1;
				}
				&#dropIllawarra:hover:before {
					background: #d7af8a;
				}
				&#dropGemco:hover:before {
					background: #a0718f;
				}
				&#dropTemco:hover:before {
					background: #cc7c61;
				}
				&#dropCerro:hover:before {
					background: #c7b5ad;
				}
			}

			&:hover:before {
				content: '';
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				height: 4px;
				width: 100%;
			}

			&:hover,
			&:focus {
				color: $brand-white;
			}
		}

		.container {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.container > .navbar-header {
			a {
				display: block;
				&:hover,
				&:focus {
					text-decoration: none;
			}
		}

		.logo {
			margin-right: 15px;
			width: 50px;
		}

		.navbar-text {
			color: $brand-white;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}

		}

		.navbar-toggle {
		  border: none;

		  &:hover,
		  &:focus {
		  	background: none;
		  }
		  .icon-bar {
		  	background-color: #9c9d9e;
		  	border-radius: 0;
		  	height: 4px;
		  	margin-bottom: 5px;
		  	width: 28px;
		  }

		}

		.navbar-nav > .open > a,
		.navbar-nav > .open > a:hover,
		.navbar-nav > .open > a:focus,   {
			background: $brand-grey;
		}

		.navbar-collapse .nav-container {
			float: right;
			@include bp(xs) {
				float: left;
			}
			margin-top: 4px;
		}

		.nav > li > a {
			padding: 10px 10px;
			font-size: 14px;
		}

		.pull-left.is-admin {
			.navbar-nav {
				color: $brand-white;
			}
		}


	}

  .test-environment {
    background: #00a087;

    .container > .navbar-header .navbar-text,
    .navbar-nav > li > a {
      color: $brand-white;
    }
  }

	.dropdown {
		a .glyphicon {
			font-size: 10px;
		}
	}

	.dropdown-menu {
		background: $brand-primary;
		border: none;
		text-transform: uppercase;
		font-family: $font-family-bold;
		border-radius: 0;

		li a {
			color: $header-text;
			&:hover {
				background: $dropdown-hover;
				color: $brand-white;
			}
		}
	}

	.search-box-container {
		max-width: 300px;
		margin-top: 8px;

		button {
			border: 0;
	    background: none;
	    color: $brand-primary;
	    padding: 2px 5px;
	    margin-top: 2px;
	    position: relative;
	    left: -28px;
	    margin-bottom: 0;

	    &:hover {
	    	background: none;
	    	color: $brand-primary;
	    }
		}

		.input-group-btn {
			z-index: 4;
		}

		input {
			text-transform: uppercase;
			font-family: $font-family-bold;
		}

	}

}

.alert-important {
	font-size: 16px;
	font-weight: 900;
	text-align: center;
}