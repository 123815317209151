// ================================================================================
// Base
// ================================================================================

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

.main {
	margin-bottom: 100px;
}

.container {
	&.xl {
		@media (min-width: 1500px) {
			width: 1440px;
		}	
	}	
}

.content-container {
	margin: 50px 0;
}
