// ================================================================================
// Footer
// ================================================================================

.footer {
	background: $brand-light-grey;
  height: 60px;
  font-family: $font-family-bold;
  position: absolute;
  bottom: 0;
  width: 100%;

  .container {
  	padding-top: 20px;
  }
}

.login-panel h1 {
    color: #fff;
    margin-top: 11px;
}