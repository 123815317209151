// ================================================================================
// Typography
// ================================================================================

// Custom Fonts
@font-face {
    font-family: 'ars_maquette_web_oneregular';
    src: url('../fonts/ARSMaquette-web-one-webfont.eot');
    src: url('../fonts/ARSMaquette-web-one-webfont.eot?#iefix') format('embedded-opentype'), 
    url('../fonts/ARSMaquette-web-one-webfont.woff2') format('woff2'), 
    url('../fonts/ARSMaquette-web-one-webfont.woff') format('woff'), 
    url('../fonts/ARSMaquette-web-one-webfont.ttf') format('truetype'), 
    url('../fonts/ARSMaquette-web-one-webfont.svg#ars_maquette_web_oneregular') format('svg');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'ars_maquette_web_onebold';
    src: url('../fonts/ARSMaquette-web-one-bold-webfont.eot');
    src: url('../fonts/ARSMaquette-web-one-bold-webfont.eot?#iefix') format('embedded-opentype'), 
    url('../fonts/ARSMaquette-web-one-bold-webfont.woff2') format('woff2'), 
    url('../fonts/ARSMaquette-web-one-bold-webfont.woff') format('woff'), 
    url('../fonts/ARSMaquette-web-one-bold-webfont.ttf') format('truetype'), 
    url('../fonts/ARSMaquette-web-one-bold-webfont.svg#ars_maquette_web_onebold') format('svg');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'ars_maquette_web_blackregular';
    src: url('../fonts/ARSMaquette-web-black-webfont.eot');
    src: url('../fonts/ARSMaquette-web-black-webfont.eot?#iefix') format('embedded-opentype'), 
    url('../fonts/ARSMaquette-web-black-webfont.woff2') format('woff2'), 
    url('../fonts/ARSMaquette-web-black-webfont.woff') format('woff'), 
    url('../fonts/ARSMaquette-web-black-webfont.ttf') format('truetype'), 
    url('../fonts/ARSMaquette-web-black-webfont.svg#ars_maquette_web_blackregular') format('svg');
    font-weight: normal;
    font-style: normal
}

$font-family-regular: 'ars_maquette_web_oneregular';
$font-family-bold: 'ars_maquette_web_onebold';
$font-family-black: 'ars_maquette_web_blackregular';


// Base Font & Icon Fonts

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'ars_maquette_web_oneregular';
$font-size-base: 14px;
$line-height-base: 1.6;

#logo {
	font-family: 'ars_maquette_web_onebold';
    text-transform: uppercase;
    color: $header-text;
}

// Headings

$headings-font-family:'ars_maquette_web_blackregular';
$headings-color: $brand-dark-grey;
$font-size-h1 : 22px; 
$font-size-h2 : 20px; 
$font-size-h3 : 18px; 
$font-size-h4 : 16px; 
$font-size-h5 : 14px; 
$font-size-h6 : 12px;

h1,h2,h3,h4,h5,h6 {
    color: $brand-dark-grey;
    text-transform: uppercase;
}

// Typography utility classes

.font-bold {
    font-family: $font-family-bold;
}
.font-black {
    font-family: $font-family-black;
}

// Links & Buttons

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;
$btn-primary-bg: $brand-yellow;
$btn-primary-border: $brand-yellow;
$btn-primary-color: $brand-dark-grey;

.btn {
    font-family: $font-family-black;
    text-transform: uppercase;    
}

.btn-primary {
    color: $brand-dark-grey;
}

.close {
    //  font-family: $font-family-black;    
    font-size: 30px;
}