// ================================================================================
// Tables
// ================================================================================

table {
	
	tbody tr {
		&.selected {
			background: $table-selected;
		}
	}

	th,
	td {
		&.centered {
			text-align: center;
		}
	}

}

.table-view-altpart {
	th {
		min-width: 155px;
		font-family: $font-family-bold;
	}
}

.table-view-notes {
	th {
		min-width: 100px;
		font-family: $font-family-bold;
	}
}

.mv-inbound-responses {
	td,
	th {
		text-align: center;
	}	
}

.table-admin {
	display: block;
	@include clearfix();

	&.is-condensed {		
		@include bp(m) {
			width: 40%;
		}
		.form-group {
			margin-bottom: 0;
		}
	}
	
	&.table-centered {
		table td,
		table th {
			text-align: center;
		}
	}

	table {
		background: $white;

		th {
			font-family: $font-family-bold;
		}

		td {
			.col-md-8 {
				padding-left: 0;
			}
		}		

	}
}
