// ================================================================================
// Variables
// ================================================================================

// Body
$body-bg: #f0f0f1;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 0 !default;

// Brand Colours
$brand-primary: #54585a;
$brand-info: #d1d6db;
$brand-success: #2ab27b;
$bootstrap-success: #dff0d8;
$progress-bar-success-bg: #2ab27b;
$brand-warning: #e2d42d;
$brand-danger: #bf674f;
$brand-yellow: #e2d42d;
$brand-yellow-alt: #e9d216;
$brand-grey: #54585a;
$brand-dark-grey: #444749;
$brand-mid-grey: #64727d;
$brand-light-grey: #d8dcdf;
$brand-light-grey-alt: #e9ecee;
$brand-white: #f0f0f0;
$white: #fff;
$brand-black: #333;
$text-color: #333;
$header-text: #c4c4c4;
$table-border: #afb2b4;
$table-alternate: #f4f7f6;
$table-selected: #f5f5f5;

// Navbar
$navbar-default-bg: #fff;
$dropdown-hover: #3e4042;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Alerts
$alert-info-bg: #d1d6db;
$alert-info-border: #d1d6db;
$alert-info-text: $brand-black;

