
#landingPage {
    .panel {
        padding: 20px;

        &:hover {
            background-color: #ccc;
        }
    }

    .panel-body img {
        float: left;
        height: 39px;
        margin-right: 20px;
    }
}

table {
    border: 1px #d6d6d6 solid;
}
