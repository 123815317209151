// --------------------------------------------------
// Forms
// --------------------------------------------------
$input-border-radius: 0;
$input-border: #bec1c3;

label {
	font-family: $font-family-bold;
}

select {
	// -webkit-appearance: none;
	// -moz-appearance: none;
	// background-image: url(...);   /* add custom arrow */
}

// hide default arrow in IE10 and IE11
// select::-ms-expand {
//     display: none;
// }

input.datepicker {
  border-radius: 0;
}

.datepicker table {
  border: none;
}

label.radio-inline {
  font-family: $font-family-regular;
}

.checkbox {
  &.has-border {
    padding: 20px 15px;
    margin: 30px 0;
    border: 1px solid $brand-light-grey;
  }
  &.selected {
    background: $bootstrap-success;
  }
}

.modal .selectize-input {
  border-radius: 0;
}

.alternatePartForm {
	.modal-body {
		p.small {
			margin-bottom: 30px;
		}
	}
}

.vendors-list {
	li {
		margin-bottom: 4px;

		.js-deletevendor {
			margin-right: 5px;
		}
	}
}

.js-add-vendor-select {
	margin-top: 5px;
}

.edit-vendor-form {

	.form-group .col-sm-7 {
		padding-left: 0;
	}

	.form-group {
		.form-content p {
			padding-top: 7px;
			margin-bottom: 0;
		}
	}
}

#expeditingForm {
	margin-bottom: 140px;
}
