// ================================================================================
// Modals
// ================================================================================

.modal {

	.modal-header {
		border-bottom: none;
	}

	.modal-footer {
		border-top: none;
	}

  h1 {
    font-size: 18px;
  }

  .overlay {
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: center;
        padding-top: 100px;
    }

}

.modal .modal-dialog {
    .modal-content {
        border-radius: 0;
        padding: 20px;
    }
}
